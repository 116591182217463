class YandexMapPickup extends YandexMap {
  constructor(selector, options, mapType) {
    console.log('mapType: ', mapType);
    super(selector, options);

    Object.assign(YandexMapPickup.prototype, YandexMapGeneral);

    this.isSelectPlacemarkCalled = false;
    this.prevPlacemarkCoords = null;
    this.mapType = mapType;

    // this.centerMapWithCurrentGeo()

    // Подписываемся на изменение адреса
    eventBus.on(`address:change`, this.onAddressСhange.bind(this));

    ymaps.ready(() => {
      this.setDefaultActivePlacemarkById()
    });
  }

  onAddressСhange(data) {
    // Выполняем изменение маркера только для карты соответствующего типа
    if (data.mapType !== this.mapType) {
      return;
    }
    console.log('data: ', data);

    this.setActivePlacemarkById(data.id)
  }

  setDefaultActivePlacemarkById() {
    const $mapContainer = $(this.el).closest('.pickup__map-container')
    const $selectedAddress = $mapContainer.find('.choices__list--dropdown .choices__item')
    const selectedAddressId = $($selectedAddress[0]).attr('data-id')

    this.setActivePlacemarkById(selectedAddressId)
  }

  getPlacemarkGeoInfo(coords) {
    return new Promise((resolve, reject) => {
      ymaps
        .geocode(coords)
        .then((res) => {
          const firstGeoObject = res.geoObjects.get(0);

          const postalCode =
            firstGeoObject.properties.get('metaDataProperty').GeocoderMetaData
              .Address.postal_code || '';
          const country = firstGeoObject.getCountry();
          const locality = firstGeoObject.getLocalities().length
            ? firstGeoObject.getLocalities()[0]
            : '';
          const thoroughfare = firstGeoObject.getThoroughfare() || '';
          const premiseNumber = firstGeoObject.getPremiseNumber() || '';
          const coords = firstGeoObject.geometry.getCoordinates();
          const latitude = coords[0];
          const longitude = coords[1];

          const geoInfo = {
            postalCode: postalCode,
            country: country,
            locality: locality,
            thoroughfare: thoroughfare,
            premiseNumber: premiseNumber,
            coords: coords,
            latitude: latitude,
            longitude: longitude,
          };

          resolve(geoInfo);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createAddressString(parts) {
    const address = parts.reduce((acc, part) => {
      part && acc.push(part);

      return acc
    }, [])

    return address.join(', ');
  }

  putPlacemarkGeoToField = async (coords) => {
    const $mapContainer = $(this.el).closest('.pickup__map-container')
    const $selectedAddress = $mapContainer.find('.choices__list--single .choices__item--selectable')

    const geoInfo = await this.getPlacemarkGeoInfo(coords);
    const address = this.createAddressString([
      geoInfo.locality,
      geoInfo.thoroughfare,
      geoInfo.premiseNumber
    ])

    $selectedAddress.text(address)
  };

  onClickPlacemark(placemark) {
    super.onClickPlacemark(placemark);

    const placemarkCoords = placemark.geometry.getCoordinates();

    this.putPlacemarkGeoToField(placemarkCoords);
  }
}

window.YandexMapPickup = YandexMapPickup;
